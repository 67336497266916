function init(el, store) {

	var uiState = {};

	el.$module.on('dispatchUiEvent', function(e, action) {

		uiState = Object.assign({}, uiState, action);

		switch (action.type) {

		case 'HIGHLIGHT_ON':

			uiState.highlightType = action.highlightType;
			uiState.highlightID = action.highlightID;
			uiState.highlightActive = true;

			if (action.highlightType === 'department' || action.highlightType === 'service') {
				uiState.tooltipOpen = true;
				uiState.tooltipText = action.highlightID;
			} else {
				delete uiState.tooltipOpen;
				delete uiState.tooltipText;
			}

			break;

		case 'HIGHLIGHT_OFF':

			delete uiState.highlightType;
			delete uiState.highlightID;
			delete uiState.highlightActive;

			delete uiState.tooltipOpen;
			delete uiState.tooltipText;

			break;

		}

		el.$module.trigger('subscribeUiEvent', [uiState]);

	});


}

export default {
	init
};
