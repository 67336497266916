import { qs } from '../../../src/assets/js/src/scripts.js';

// Components shared with web and galaxo
import { default as stage } from './floor-map-stage.js';
import { default as reducer } from './floor-map-reducer.js';
import { default as businessShortinfo } from './floor-map-business-shortinfo.js';
import { default as router } from './floor-map-router.js';
import { default as trail } from './floor-map-trail.js';
import { default as footer } from './floor-map-footer.js';

// Components for web
import { default as tooltip } from './floor-map-tooltip.js';
import { default as popover } from './floor-map-popover.js';
import { default as controls } from './floor-map-controls.js';
import { default as panes } from './floor-map-panes.js';
import { default as uiReducer } from './floor-map-ui-reducer.js';

function init(store) {

	const el = {};

	el.$module = $('.floor-map--web');

	if (!el.$module.length) {
		return;
	}

	el.$controls = el.$module.find('.btn-list');
	el.$floors = el.$module.find('.floor-map__floor');
	el.$stages = el.$floors.find('.floor-map__floor-stage');
	el.$maps = el.$stages.find('> svg');
	el.$businessShortinfos = el.$module.find('.business-shortinfo');

	store.dispatch({
		type: 'ADD_BUSINESS_DATA',
		businesses: window.valkeaFloorMapData.businesses,
	});

	businessShortinfo.init(el);
	controls.init(el, store);
	popover.init(el, store);
	tooltip.init(el, store);
	trail.init(el, store);
	footer.init(el, store);
	stage.init(el, store, 'web');
	panes.init(el, store);
	uiReducer.init(el, store);
	router.init(el, store);

	/*
	 * Ready!
	 * First timeOut is a Safari workaround. It didn't show the
	 * popover if an initial business was selected (in url params) on page load
	 * Second timeout is to show initial state without animations.
	 * TODO: Avoid using timeouts
	 */
	window.setTimeout(function() {

		var initialState;

		if (window.history && window.history.state) {
			initialState = window.history.state;
		} else if (window.location.search) {
			initialState = qs.parse(window.location.search.replace('?', ''));
		}

		if (initialState) {
			store.dispatch({
				type: 'LOAD_ROUTE',
				route: initialState
			});
		}

		window.setTimeout(function() {
			el.$module.addClass('is-ready');
		}, 500);

	}, 10);

}




/**
 * Module exports
 */

export default {
	init,
	reducer
};
