export default function floorMapReducer(oldState = {}, action) {

	var state = Object.assign({}, oldState);

	switch (action.type) {

	case 'ADD_BUSINESS_DATA':

		state.businesses = action.businesses;

		// HOX! return statement, not break.
		return state;

	case 'STAGE_TRANSITION_END':

		delete state.stageTransition;

		// HOX! return statement, not break.
		return state;

	case 'PANE_TRANSITION_END':

		delete state.paneTransition;

		// HOX! return statement, not break.
		return state;

	case 'LOAD_ROUTE':

		/*
		 * Load state from browser history or url
		 */

		state.business = state.businesses.find(businessInfo =>
			businessInfo.id && businessInfo.id === parseInt(action.route.business, 10) || businessInfo.premise.includes(action.route.premise)
		);

		state.activePremise = action.route.premise;

		if (state.locations) {
			state.location = state.locations.find(location => location.name === action.route.location);
		}

		if (state.location) {
			state.floor = state.location.floor;
			state.department = state.location.department;
		} else {
			state.serviceID = action.route.service;
			state.floor = action.route.floor;
			state.department = action.route.department;
		}

		state.routePopstate = action.routePopstate;

		break;


	case 'SELECT_FLOOR':

		let floorIDformatted = String(action.floorID).toLowerCase();

		state = deleteActiveAndSelectedThings(state);

		delete state.department;

		state.floor = ['k1', '1', '2', '3', '4'].includes(floorIDformatted) && floorIDformatted;

		break;

	case 'SELECT_DEPARTMENT':

		state = deleteActiveAndSelectedThings(state);
		state.department = action.departmentID;

		break;

	case 'SELECT_BUSINESS':

		state = deleteActiveAndSelectedThings(state);

		state.business = state.businesses.find(businessInfo =>
			businessInfo.id && (businessInfo.id === action.businessID) || businessInfo.premise.includes(action.premiseID)
		);

		break;

	case 'SELECT_PREMISE':

		state = deleteActiveAndSelectedThings(state);

		state.activePremise = action.premiseID;

		break;

	case 'SELECT_SERVICE':

		state = deleteActiveAndSelectedThings(state);

		state.serviceID = action.service;
		state.floor = action.floorID;
		state.department = action.department;

		break;

	case 'SELECT_LOCATION':

		state = deleteActiveAndSelectedThings(state);

		delete state.department;

		state.location = state.locations.find(location => location.name === action.locationName);

		if (state.location) {
			state.floor = state.location.floor;
			state.department = state.location.department;
		}

		break;

	case 'STEP_BACK':

		state = deleteActiveAndSelectedThings(state);

		if (state.env === 'galaxo') {
			delete state.department;
			delete state.floor;
		} else {

			if (state.department) {
				delete state.department;
			} else {
				delete state.floor;
			}

		}

		break;

	case 'UNSELECT':

		state = deleteActiveAndSelectedThings(state);

		if (state.env === 'galaxo') {
			delete state.department;
		}

		break;

	}

	/*
	 * Selection
	 */

	if (state.activePremise && !state.business) {
		state.business = state.businesses.find(businessInfo =>
			businessInfo.premise.includes(state.activePremise)
		);
	}

	if (state.business) {

		if (!state.activePremise) {
			state.activePremise = state.business.premise_primary;
		}

		state.selectionID = state.business.id || state.business.premise_primary;
		state.selectionType = 'business';
		state.selectionActive = true;
		state.selectionZoom = action.selectionZoom || (action.type === 'LOAD_ROUTE');

		let premiseDetails = state.business.premise_details.find(premise => premise.premise_id === (state.activePremise || state.business.premise_primary));

		state.floor = premiseDetails.floor_id;
		state.department = premiseDetails.department_id;

		state.selectionPremises	= state.activePremise && (state.business.has_multiple_floors || state.business.has_multiple_titles)
															? [state.activePremise]
															: state.business.premise;

	} else if (state.serviceID) {

		state.selectionType = 'service';
		state.selectionID = state.serviceID;
		state.selectionActive = true;
		state.serviceData = state.businesses.find(businessInfo => {
			return businessInfo.premise && businessInfo.premise.includes(state.serviceID)
					|| businessInfo.title === state.serviceID;
		});

		if (state.serviceData) {
			state.selectionID = state.serviceID = state.serviceData.title;
			state.selectionPremises = state.serviceData ? state.serviceData.premise : null;
			state.department = (state.env === 'galaxo') ? undefined : state.serviceData.department;
		}

	} else if (state.location) {

		state.selectionType = 'location';
		state.selectionID = state.location.name;
		state.selectionActive = true;

	}



	/*
	 * Popover
	 */

	state.popoverOpen = state.footerInfoOpen = state.selectionActive && state.selectionType === 'business';

	if (!state.popoverOpen || state.department !== oldState.department) {
		delete state.popoverClickCoords;
	} else if (action.type === 'SELECT_BUSINESS' || action.type === 'SELECT_PREMISE') {
		state.popoverClickCoords = action.clickCoords;
	}


	/*
	 * Entrance pane/tab/view/whatever
	 */
	if (action.entranceID || (action.type === 'LOAD_ROUTE' && action.route.view && action.route.view !== 'floor')) {
		state = deleteActiveAndSelectedThings(state);
		delete state.step;
		delete state.floor;
		delete state.department;
		state.pane = action.entranceID || action.route.view;
		state.paneTransition = true;
	} else {
		state.pane = 'floor';
	}

	/*
	 * Determine if state change results in stage transition animation.
	 * Some functions will have to be run asynchronously.
	 */
	state.stageTransition = (state.floor !== oldState.floor || (state.department !== oldState.department && state.env !== 'galaxo'));


	/*
	 * Step
	 */
	if (state.pane && state.pane !== 'floor') {
		// Entrance view
		delete state.step;
	} else if (state.floor && state.department) {
		// Department view (zoomed)
		state.step = 3;
	} else if (state.floor) {
		// Single floor view
		state.step = 2;
	} else {
		// Floor stack view
		state.step = 1;
	}

	// Nametags
	if (state.step > 1 || (state.env === 'galaxo' && [2, 3].includes(state.step))) {
		state.nametagsOpen = true;
	} else {
		delete state.nametagsOpen;
	}

	// Misc cleanup
	if (action.type !== 'LOAD_ROUTE') {
		delete state.routePopstate;
	}


	return state;

}

// Helper to clear most of the unnecessary stuff on state change
function deleteActiveAndSelectedThings(state) {

	delete state.business;
	delete state.activePremise;
	delete state.service;
	delete state.serviceID;
	delete state.serviceData;
	delete state.location;
	delete state.popoverOpen;
	delete state.popoverOpenClickCoords;
	delete state.footerInfoOpen;
	delete state.selectionID;
	delete state.selectionType;
	delete state.selectionActive;
	delete state.selectionZoom;
	delete state.selectionPremises;
	delete state.tooltipOpen;
	delete state.tooltipText;

	return state;

}
