var $module;
var $floors;
var $maps;
var $stages;
var $currentFloor = jQuery('');
//var $currentFloor = 1;
var hoverActive = false;
var isAnimated = false;
var oldState = {};
var localUiState = {};
var clickTrigger;
var isGalaxo;

/*

██ ███    ██ ██ ████████ ██  █████  ██      ██ ███████ ███████
██ ████   ██ ██    ██    ██ ██   ██ ██      ██    ███  ██
██ ██ ██  ██ ██    ██    ██ ███████ ██      ██   ███   █████
██ ██  ██ ██ ██    ██    ██ ██   ██ ██      ██  ███    ██
██ ██   ████ ██    ██    ██ ██   ██ ███████ ██ ███████ ███████

*/

function init(el, store) {

	$module = el.$module;
	$floors = el.$floors;
	$maps = el.$maps;
	$stages = el.$stages;

	isGalaxo = store.getState().floorMap.env === 'galaxo';
	// clickTrigger = (isGalaxo) ? 'mousedown' : 'click';
	clickTrigger = 'click';

	// Register events
	registerTransitionEvents(store);
	registerClickEvents(store);
	registerUiEvents(store);

	// Initial state
	handleChange(el, store);

	// State updates
	store.subscribe(() => {
		handleChange(el, store);
	});

	setDisabledPremises(store);

}

/*

██    ██ ██████  ██████   █████  ████████ ███████ ███████
██    ██ ██   ██ ██   ██ ██   ██    ██    ██      ██
██    ██ ██████  ██   ██ ███████    ██    █████   ███████
██    ██ ██      ██   ██ ██   ██    ██    ██           ██
 ██████  ██      ██████  ██   ██    ██    ███████ ███████

*/

function handleChange(el, store) {

	var state = store.getState();
	var stageTransitionChanged = state.floorMap.stageTransition !== oldState.stageTransition;
	var selectionChanged = state.floorMap.selectionID !== oldState.selectionID;
	var stepChanged = state.floorMap.step !== oldState.step;
	var floorChanged = state.floorMap.floor !== oldState.floor;
	var departmentChanged = state.floorMap.department !== oldState.department;
	var paneChanged = state.floorMap.pane !== oldState.pane;
	var premiseChanged = state.floorMap.activePremise !== oldState.activePremise;
	var selectionShouldUpdate = (selectionChanged || stageTransitionChanged || paneChanged || premiseChanged);
	var hoverAvailable = !isGalaxo;

	if (state.floorMap.floor !== oldState.floor) {
		$currentFloor = $(`#floor-map__floor--${state.floorMap.floor}`);
	}

	// Hovers / highlights

	if (hoverAvailable) {

		if (state.root.mql_LG) {
			if (!hoverActive) {
				registerHoverEvents(el, store);
			}
		} else {
			if (hoverActive) {
				removeHoverEvents(el, store);
			}
		}

	}

	// Clicks / selects

	if (stepChanged) {
		selectStep(state.floorMap.step);
	}
	if (floorChanged) {
		selectFloor(state.floorMap.floor, state.floorMap.step);
	}
	if (departmentChanged || floorChanged) {
		selectDepartment(state.floorMap.department);
	}

	// Nametags
	if (floorChanged || stepChanged) {
		$module.removeClass('nametags--open');
	}
	if (!state.floorMap.stageTransition && state.floorMap.nametagsOpen) {
		$module.addClass('nametags--open');
	}

	if (selectionShouldUpdate) {

		unselectService();
		unselectPremise();

		// Maybe asynchronous changes (waits for another state update from transitionend)
		if (!state.floorMap.stageTransition) {
			switch (state.floorMap.selectionType) {
			case 'service':
				selectService(state.floorMap.selectionID, state.floorMap.serviceData);
				if (state.floorMap.selectionPremises) {
					selectPremise(null, state.floorMap.selectionPremises);
				}
				break;
			case 'business':
				selectPremise(state.floorMap.business, state.floorMap.selectionPremises, state.floorMap.activePremise);
				break;
			}

			// Zoom animation for mobile devices. To help notice where the
			// selected business/premise is on the map.
			if (state.floorMap.selectionZoom) {
				$module.addClass('premise-zoom');
			} else {
				$module.removeClass('premise-zoom');
			}

			isAnimated = false;

		} else {

			isAnimated = true;

		}

	}

	// Highlights
	$module.trigger('dispatchUiEvent', [{ type: 'HIGHLIGHT_OFF' }]);

	oldState = Object.assign({}, state.floorMap);

}


/*

███████ ██    ██ ███████ ███    ██ ████████ ███████
██      ██    ██ ██      ████   ██    ██    ██
█████   ██    ██ █████   ██ ██  ██    ██    ███████
██       ██  ██  ██      ██  ██ ██    ██         ██
███████   ████   ███████ ██   ████    ██    ███████

*/


function registerTransitionEvents(store) {

	$stages.on('transitionend', function(e) {

		var correctPhase = e.eventPhase === 2;
		var correctProperty = ['transform', 'opacity'].includes(e.originalEvent.propertyName);

		if (correctPhase && correctProperty) {

			// Prevent sending two actions (transform & opacity)
			if (isAnimated) {
				store.dispatch({
					type: 'STAGE_TRANSITION_END'
				});
			}

			isAnimated = false;

		}
	});

}


function registerUiEvents(store) {

	$module.on('subscribeUiEvent', function(e, uiState) {

		var highlightShouldUpdate = (uiState.highlightID !== localUiState.highlightID || uiState.highlightActive !== localUiState.highlightActive);

		if (highlightShouldUpdate) {
			highlightFloor(uiState.highlightID, oldState.step, uiState.highlightActive);
			highlightDepartment(uiState.highlightID, oldState.step, uiState.highlightActive);
			highlightPremise(uiState.highlightID, oldState.step, uiState.highlightActive, store);
			highlightService(uiState.highlightID, oldState.step, uiState.highlightActive);
		}

		localUiState = Object.assign({}, uiState);

	});

}


/*

███████ ██    ██ ███████ ███    ██ ████████ ███████        ██   ██  ██████  ██    ██ ███████ ██████
██      ██    ██ ██      ████   ██    ██    ██      ██     ██   ██ ██    ██ ██    ██ ██      ██   ██
█████   ██    ██ █████   ██ ██  ██    ██    ███████        ███████ ██    ██ ██    ██ █████   ██████
██       ██  ██  ██      ██  ██ ██    ██         ██ ██     ██   ██ ██    ██  ██  ██  ██      ██   ██
███████   ████   ███████ ██   ████    ██    ███████        ██   ██  ██████    ████   ███████ ██   ██

*/


function registerHoverEvents(el, store) {

	var $maps = el.$maps;

	// Floor hover
	$maps.on('mouseenter mouseleave', '.Kerros', function(e) {

		if (oldState.step === 1 && !isAnimated) {
			$module.trigger('dispatchUiEvent', [{
				type: (e.type === 'mouseenter') ? 'HIGHLIGHT_ON' : 'HIGHLIGHT_OFF',
				highlightType: 'floor',
				highlightID: $(this).closest('.floor-map__floor').data('floor'),
				highlightActive: (e.type === 'mouseenter')
			}]);
		}

	});

	// Department hover
	$maps.on('mouseenter mouseleave', '.department', function(e) {

		/*
		if (oldState.step === 2 && !isAnimated) {
			$module.trigger('dispatchUiEvent', [{
				type: (e.type === 'mouseenter') ? 'HIGHLIGHT_ON' : 'HIGHLIGHT_OFF',
				highlightType: 'department',
				highlightID: $(this).data('id')
			}]);
		}
		*/

	});

	// Business hover
	$stages.on('mouseenter mouseleave', '.premise:not(.disabled), .floor-map__nametag:not(.disabled)', function(e) {

		var premiseID = $(this).data('premise');

		//if (oldState.step === 3 && !isAnimated) {
		if (oldState.step === 2 && !isAnimated) {
			$module.trigger('dispatchUiEvent', [{
				type: (e.type === 'mouseenter') ? 'HIGHLIGHT_ON' : 'HIGHLIGHT_OFF',
				highlightType: 'business',
				highlightID: premiseID
			}]);
		}
	});

	// Service hover
	$maps.on('mouseenter mouseleave', '.services', function(e) {

		if (oldState.step === 2 || oldState.step === 3 && !isAnimated) {
			$module.trigger('dispatchUiEvent', [{
				type: (e.type === 'mouseenter') ? 'HIGHLIGHT_ON' : 'HIGHLIGHT_OFF',
				highlightType: 'service',
				highlightID: $(this).children().first().data('name')
			}]);
		}

	});

	hoverActive = true;

}


function removeHoverEvents() {
	$maps.off('mouseenter mouseleave');
	$stages.off('mouseenter mouseleave');
	hoverActive = false;
}


/*

███████ ██    ██ ███████ ███    ██ ████████ ███████         ██████ ██      ██  ██████ ██   ██
██      ██    ██ ██      ████   ██    ██    ██      ██     ██      ██      ██ ██      ██  ██
█████   ██    ██ █████   ██ ██  ██    ██    ███████        ██      ██      ██ ██      █████
██       ██  ██  ██      ██  ██ ██    ██         ██ ██     ██      ██      ██ ██      ██  ██
███████   ████   ███████ ██   ████    ██    ███████         ██████ ███████ ██  ██████ ██   ██

*/


function registerClickEvents(store) {

	// Floor select
	$module.on(clickTrigger, '.Kerros, .floor-map__floor-number', function(e) {
		var floorID = $(this).closest('.floor-map__floor').data('floor') || $(this).data('floor');

		if (oldState.step === 1) {
			store.dispatch({
				type: 'SELECT_FLOOR',
				floorID: floorID
			});

		}

	});

	// Business select
	$stages.on(clickTrigger, '.premise, .floor-map__nametag', function(e) {

		var premiseID = $(this).data('premise');
		var businessShortinfo = getBusinessByPremise(premiseID, store) || {};
		var departmentID = businessShortinfo.department || $(this).closest('.department').data('id');

		if (isGalaxo) {
			e.stopPropagation();
		}

		if ($(this).is('.disabled') && !isGalaxo) {

			store.dispatch({
				type: 'SELECT_DEPARTMENT',
				departmentID: departmentID
			});

		} else {

			store.dispatch({
				type: 'SELECT_PREMISE',
				premiseID: premiseID,
				clickCoords: $(this).is('.premise') ? { left: e.pageX, top: e.pageY } : undefined
			});

		}

	});

	// Service select
	$maps.on(clickTrigger, '.service', function(e) {

		var serviceName = $(this).data('name');

		if (isGalaxo) {
			e.stopPropagation();
		}

		if (oldState.step === 2 || oldState.step === 3) {

			store.dispatch({
				type: 'SELECT_SERVICE',
				service: serviceName,
				floorID: oldState.floor,
				department: isGalaxo ? undefined : oldState.department
			});

		}

	});

	// Unselect premise/service on outside click
	// Some elements may have e.stopPropagation to
	// prevent them from also triggering this unselect.
	$(document).on(clickTrigger, function(e) {

		if (isGalaxo) {

			let galaxoFooterEl = document.querySelector('.site-footer');
			let galaxoSearchEl = document.querySelector('.galaxo-search');
			let clickedInSiteFooter = galaxoFooterEl && galaxoFooterEl.contains(e.target);
			let clickedInSearch = galaxoSearchEl && galaxoSearchEl.contains(e.target);

			// Some clicks should be ignored
			if (oldState.selectionActive && !clickedInSearch && !clickedInSiteFooter) {
				store.dispatch({
					type: 'UNSELECT'
				});
			}

		} else if (oldState.selectionActive) {

			let popoverEl = document.querySelector('.floor-map__popover');
			let controlsEl = document.querySelector('.floor-map__controls');
			let trailBackEl = document.querySelector('.floor-map__trail-back');
			let clickedInPopover = popoverEl && popoverEl.contains(e.target);
			let clickedInTrailBack = trailBackEl && trailBackEl.contains(e.target);
			let clickedInControls = controlsEl && controlsEl.contains(e.target);
			let clickedInPremise = $(e.target).is('.premise');
			let clickedInService = $(e.target).is('.service');
			let clickedInNametag = $(e.target).is('.floor-map__nametag');
			let clickedInBusinessShortinfoLink = $(e.target).is('a') && $(e.target).parent().is('.business-shortinfo__footer');

			if (
				!clickedInPopover
				&& !clickedInTrailBack
				&& !clickedInControls
				&& !clickedInPremise
				&& !clickedInService
				&& !clickedInNametag
				&& !clickedInBusinessShortinfoLink
			) {
				store.dispatch({
					type: 'UNSELECT'
				});
			}

		}

	});

}


/*

██████  ███████ ███    ██ ██████  ███████ ██████      ██████   ██████  ███    ███
██   ██ ██      ████   ██ ██   ██ ██      ██   ██     ██   ██ ██    ██ ████  ████
██████  █████   ██ ██  ██ ██   ██ █████   ██████      ██   ██ ██    ██ ██ ████ ██
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██     ██   ██ ██    ██ ██  ██  ██
██   ██ ███████ ██   ████ ██████  ███████ ██   ██     ██████   ██████  ██      ██

*/


/**
 * Step handler
 */
function selectStep(step) {
	$module.removeClass('step-1 step-2 step-3').addClass(`step-${step || 1}`);
}

/**
 * Floor handlers
 */
function highlightFloor(floorID, currentStep, isActive) {
	var $floor = $(`#floor-map__floor--${floorID}`);
	$floors.removeClass('is-highlight');

	if ($floor.length && currentStep === 1 && isActive) {
		$floor.addClass('is-highlight');
	}
}

function selectFloor(floorID, currentStep) {
	var $floor = $(`#floor-map__floor--${floorID}`);
	$floors.removeClass('is-active');

	if ($floor.length) {
		$currentFloor = $floor;
		$floor.addClass('is-active');
	}
}

/**
 * Department handlers
 */

function highlightDepartment(departmentID, currentStep, isActive) {

	var $department = $currentFloor.find(`.department[data-id="${departmentID}"]`);

	$maps.find('.department').removeClass('is-highlight');
	$module.removeClass('has-highlight-department');

	if ($department.length && isActive) {
		$module.addClass('has-highlight-department');
		$department.addClass('is-highlight');
	}

}

function selectDepartment(departmentID) {

	var $currentDepartment = $currentFloor.find(`.department[data-id="${departmentID}"]`);

	$module.removeClass('has-selected-department');
	$stages.removeAttr('data-active-department');
	$maps.find('.department').removeClass('is-active');

	if ($currentDepartment.length) {

		if (!isGalaxo) {
			$module.addClass('has-selected-department');
		}

		$currentFloor.find('.floor-map__floor-stage').attr('data-active-department', departmentID);
		$currentDepartment.addClass('is-active');
	}

}




/**
 * Business handlers
 */

function highlightPremise(premiseID, currentStep, isActive, store) {

	var businessInfo = getBusinessByPremise(premiseID, store);

	$stages.find('.floor-map__nametag').removeClass('is-highlight');

	if (isActive && businessInfo) {
		$(`#floor-map__nametag--${businessInfo.has_multiple_titles ? premiseID : businessInfo.premise_primary}`).addClass('is-highlight');
	}

}

function selectPremise(businessInfo, premises, activePremise) {

	var premiseSelector = premises.map(function(premiseID) { return `#premise--${premiseID}`; }).join(',');
	var $premises = $(premiseSelector);
	var $icon = $currentFloor.find(`.icon[data-name="${businessInfo.icon}"]`);

	if ($premises.length) {

		if (activePremise) {
			let activatePrimaryNametag = !businessInfo.has_multiple_titles && !businessInfo.has_multiple_floors;
			$(`#floor-map__nametag--${activatePrimaryNametag ? businessInfo.premise_primary : activePremise}`).addClass('is-active');
		}

		$premises.addClass('is-active');
		$module.addClass('has-selected-premise');
		$icon.addClass('is-active');
	}


}

function unselectPremise() {
	$stages.find('.floor-map__nametag').removeClass('is-active');
	$maps.find('.premise').removeClass('is-active');
	$module.removeClass('has-selected-premise');
	$maps.find('.icon').removeClass('is-active');
}


/**
 * Service handlers
 */

function highlightService(name, currentStep, isActive) {

	var selectorName = (name === 'Lastenvessa' || name === 'Invavessa') ? 'Vessa' : name;
	var $currentService = $currentFloor.find(`.service[data-name="${selectorName}"]`);

	$module.removeClass('has-highlight-service');
	$maps.find('.service').removeClass('is-highlight');

	if ($currentService.length && currentStep !== 1 && isActive) {
		$module.addClass('has-highlight-service');
		$currentService.addClass('is-highlight');
	}

}

function selectService(name, serviceData) {

	var selectorName = (name === 'Lastenvessa' || name === 'Invavessa') ? 'Vessa' : name;
	var $currentService = $currentFloor.find(`.service[data-name="${selectorName}"]`);
	var $currentIcon = $currentFloor.find(`.icon[data-name="${selectorName}"]`);

	if (serviceData && Array.isArray(serviceData.premise)) {
		$currentIcon = $currentFloor.find(`.icon[data-name="${serviceData.title}"]`);
		serviceData.premise.forEach(premiseID => {
			$currentService = $currentService.add($currentFloor.find(`.service[data-name="${premiseID}"]`));
		});
	}

	if ($currentService.length || $currentIcon.length) {

		$module.addClass('has-selected-service');
		$currentService.addClass('is-active');
		$currentIcon.addClass('is-active');

		if (selectorName === 'Vessa') {
			$currentFloor.find('.icon[data-name="Lastenvessa"]').addClass('is-active');
			$currentFloor.find('.icon[data-name="Invavessa"]').addClass('is-active');
		}

	}

}

function unselectService() {

	$module.removeClass('has-selected-service');
	$maps.find('.service').removeClass('is-active');
	$maps.find('.icon').removeClass('is-active');

}




/*

██   ██ ███████ ██      ██████  ███████ ██████  ███████
██   ██ ██      ██      ██   ██ ██      ██   ██ ██
███████ █████   ██      ██████  █████   ██████  ███████
██   ██ ██      ██      ██      ██      ██   ██      ██
██   ██ ███████ ███████ ██      ███████ ██   ██ ███████

*/


function setDisabledPremises(store) {

	var state = store.getState();

	// TODO: shouldn't need to check for disabled premises. All premises are known, and if one doesn't
	//       have any business/service assigned, it should be disabled by default.

	var disabledPremises = state.floorMap.businesses.filter(business => business.disabled);

	// Check to make sure that a business is not unnecessarily disabled.
	// Case: a premise has been marked as disabled in the `floor-map-business-data.json` and later
	// a business post is added to WP with that premise ID. If the dev forgets to remove the premise
	// from the json, it will be falsely disabled (meaning: disabled even though a business exists
	// in that premise). The business post should take priority.
	var falselyDisabled = disabledPremises.filter(disabledBusiness => {
		return (state.floorMap.businesses.filter(business => business.premise.includes(disabledBusiness.premise[0])).length > 1); // TODO: fix for multi-premise businesses
	});

	disabledPremises.forEach(business => {
		if (!falselyDisabled.includes(business)) {
			$(`#premise--${business.premise[0]}`).addClass('disabled'); // TODO: fix for multi-premise businesses
		}
	});
}

function getBusinessByPremise(premiseID, store) {
	var state = store.getState();
	var businessDatas = state.floorMap.businesses.filter((business) => (business.premise && business.premise.includes(premiseID)));
	return businessDatas ? businessDatas.pop() : null;
}



/*

███████ ██   ██ ██████   ██████  ██████  ████████ ███████
██       ██ ██  ██   ██ ██    ██ ██   ██    ██    ██
█████     ███   ██████  ██    ██ ██████     ██    ███████
██       ██ ██  ██      ██    ██ ██   ██    ██         ██
███████ ██   ██ ██       ██████  ██   ██    ██    ███████

*/

export default {
	init
};
