import { qs } from '../../assets/js/src/scripts.js';

var controlsOpen = false;
var dropdownsOpen = false;
var $module;
var $controlsContainer;
var $controls;
var $dropdowns;
var $floorControls;
var $entranceControls;
var $departmentControls;
var oldState = { floorMap: {}, root: {} };

/*

██ ███    ██ ██ ████████ ██  █████  ██      ██ ███████ ███████
██ ████   ██ ██    ██    ██ ██   ██ ██      ██    ███  ██
██ ██ ██  ██ ██    ██    ██ ███████ ██      ██   ███   █████
██ ██  ██ ██ ██    ██    ██ ██   ██ ██      ██  ███    ██
██ ██   ████ ██    ██    ██ ██   ██ ███████ ██ ███████ ███████

*/

function init(el, store) {

	$module = el.$module;
	$controlsContainer = $module.find('.floor-map__controls');
	$controls = el.$controls;

	$dropdowns = $controlsContainer.find('.btn-list--dropdown > ul > li');
	$floorControls = $controls.find('button[data-type="floor"]');
	//$entranceControls = $controls.find('button[data-type="entrance"]');
	$departmentControls = $controls.find('button[data-type="department"]');

	registerEvents(store);

	handleChange(store);

	store.subscribe(() => {
		handleChange(store);
	});

	// Mobile controls overlay
	$module.on('click', '.floor-map__controls-header', function(e) {

		if (controlsOpen) {
			hideControlsOverlay();
		} else {
			showControlsOverlay();
		}
	});

	// Accordion / dropdown
	$module.on('click', '.floor-map__controls .btn--dropdown', function(e) {

		if (store.getState().root.mql_XS) {
			$(this).toggleClass('is-open').next().stop(true).slideToggle(200);
		} else {
			$(this).toggleClass('is-open').next().toggleClass('is-open');
		}

		if ($dropdowns.find('.dropdown.is-open').length) {
			dropdownsOpen = true;
		} else {
			dropdownsOpen = false;
		}

	});

}

/*

███████ ██    ██ ███████ ███    ██ ████████ ███████
██      ██    ██ ██      ████   ██    ██    ██
█████   ██    ██ █████   ██ ██  ██    ██    ███████
██       ██  ██  ██      ██  ██ ██    ██         ██
███████   ████   ███████ ██   ████    ██    ███████

*/

function registerEvents(store) {

	// Floor hover
	$controls.on('mouseenter mouseleave', '[data-type="floor"]', function(e) {
		var state = store.getState();
		if (state.floorMap.pane === 'floor' && !state.root.mql_XS && state.floorMap.step === 1) {
			$module.trigger('dispatchUiEvent', [{
				type: (e.type === 'mouseenter') ? 'HIGHLIGHT_ON' : 'HIGHLIGHT_OFF',
				highlightType: 'floor',
				highlightID: $(e.currentTarget).data('id')
			}]);
		}
	});

	// Floor select
	$controls.on('click', '[data-type="floor"]', function(e) {
		var floorID = $(this).attr('data-id');

		store.dispatch({
			type: 'SELECT_FLOOR',
			floorID: floorID
		});
	});

	// Department hover
	$controls.on('mouseenter mouseleave', '[data-type="department"]', function(e) {
		var state = store.getState();
		if (state.floorMap.step === 2) {
			$module.trigger('dispatchUiEvent', [{
				type: (e.type === 'mouseenter') ? 'HIGHLIGHT_ON' : 'HIGHLIGHT_OFF',
				highlightType: 'department',
				highlightID: $(e.currentTarget).data('id')
			}]);
		}
	});

	// Department select
	$controls.on('click', '[data-type="department"]', function(e) {
		var departmentID = $(this).data('id');

		store.dispatch({
			type: 'SELECT_DEPARTMENT',
			departmentID: departmentID
		});
	});

	// Entrance select
	$controls.on('click', '[data-type="entrance"]', function(e) {
		var entranceID = $(this).attr('data-id');

		store.dispatch({
			type: 'SELECT_PANE',
			entranceID: entranceID
		});
	});

	$module.on('click', '.dropdown a', function(e) {

		var params = qs.parse(this.href.split('?')[1]);

		e.preventDefault();


		if (params.premise) {
			store.dispatch({
				type: 'SELECT_PREMISE',
				premiseID: params.premise,
				selectionZoom: true
			});
		} else if (params.service) {
			store.dispatch({
				type: 'SELECT_SERVICE',
				service: params.service,
				floorID: params.floor
			});
		}

	});


	// Close on outside click
	$(document).on('click.floorMapDropdowns', function(e) {

		var clickedInDropdown = false;

		if ($dropdowns.length && dropdownsOpen) {

			let i = 0;

			while (i < $dropdowns.length && !clickedInDropdown) {
				clickedInDropdown = $.contains($dropdowns[i], e.target);
				i++;
			}

			if (!clickedInDropdown) {
				closeDropdowns();
			}

		}

	});

}

/*

██    ██ ██████  ██████   █████  ████████ ███████ ███████
██    ██ ██   ██ ██   ██ ██   ██    ██    ██      ██
██    ██ ██████  ██   ██ ███████    ██    █████   ███████
██    ██ ██      ██   ██ ██   ██    ██    ██           ██
 ██████  ██      ██████  ██   ██    ██    ███████ ███████

*/

function handleChange(store) {

	var state = store.getState();
	var selectionChanged = state.floorMap.selectionID !== oldState.floorMap.selectionID;
	var premiseChanged = state.floorMap.activePremise !== oldState.floorMap.activePremise;

	// Active business/service

	$controlsContainer.find('.btn--dropdown, .dropdown').removeClass('is-open');

	// Businesses and services
	if (selectionChanged || premiseChanged) {

		let selector;

		switch (state.floorMap.selectionType) {
		case 'business':
			selector = `[data-premise="${state.floorMap.activePremise}"]`;
			break;
		case 'service':
			selector = `[data-floor="${state.floorMap.floor}"][data-service="${state.floorMap.selectionID}"]`;
			break;
		}

		$controlsContainer.find('.dropdown a').removeClass('is-current').filter(selector).addClass('is-current');

	}


	// Active floor or entrance
	if (state.floorMap.floor !== oldState.floorMap.floor || state.floorMap.pane !== oldState.floorMap.pane) {
		$floorControls.removeClass('is-active').filter(`[data-id="${state.floorMap.floor}"]`).addClass('is-active');
		//$entranceControls.removeClass('is-active').filter(`[data-id="${state.floorMap.pane}"]`).addClass('is-active');
	}

	// Highlight floor buttons
	// if (state.floorMap.step === 1 && state.floorMap.highlightID !== oldState.floorMap.highlightID) {
	// 	$floorControls.removeClass('is-active').filter(`[data-id="${state.floorMap.highlightID}"]`).addClass('is-active');
	// }

	if (!state.floorMap.floor !== oldState.floorMap.floor || state.floorMap.department !== oldState.floorMap.department) {
		departmentUpdate(state);
	}

	if (state.root.mql_XS && dropdownsOpen) {
		closeDropdowns();
	}

	if (controlsOpen) {
		hideControlsOverlay();
	}

	oldState = Object.assign({}, state);

}


/*

██████   ██████  ███    ███      █████   ██████ ████████ ██  ██████  ███    ██ ███████
██   ██ ██    ██ ████  ████     ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
██   ██ ██    ██ ██ ████ ██     ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
██   ██ ██    ██ ██  ██  ██     ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
██████   ██████  ██      ██     ██   ██  ██████    ██    ██  ██████  ██   ████ ███████

*/

function showControlsOverlay() {
	$('html, body').animate({ scrollTop: 0 });
	$(document.body).addClass('floor-map-controls-open');
	$('.floor-map__controls').addClass('is-open');

	$('.floor-map__controls-header, .top-bar').on('touchmove.fixed', function(e) {
		e.preventDefault();
	});

	controlsOpen = true;
}

function hideControlsOverlay() {
	$(document.body).removeClass('floor-map-controls-open');
	$('.floor-map__controls').removeClass('is-open');
	$('.floor-map__controls-header, .top-bar').off('touchmove.fixed');
	controlsOpen = false;
}

function closeDropdowns() {
	$('.floor-map__controls').find('.dropdown, .is-open').removeClass('is-open').removeAttr('style');
	dropdownsOpen = false;
}

function departmentUpdate(state) {

	// Enabled/disabled & active department
	$departmentControls.removeClass('is-active').each(function() {

		var $button = $(this);
		var $currentFloor = $(`#floor-map__floor--${state.floorMap.floor}`);
		var floorHasDepartment = $currentFloor && $currentFloor.find(`.department[data-id="${$button.data('id')}"]`).length;

		$button.prop('disabled', !floorHasDepartment);

	}).filter(`[data-id="${state.floorMap.department}"]`).addClass('is-active');

}

/*

███████ ██   ██ ██████   ██████  ██████  ████████ ███████
██       ██ ██  ██   ██ ██    ██ ██   ██    ██    ██
█████     ███   ██████  ██    ██ ██████     ██    ███████
██       ██ ██  ██      ██    ██ ██   ██    ██         ██
███████ ██   ██ ██       ██████  ██   ██    ██    ███████

*/

export default {
	init
};
