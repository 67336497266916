import { qs } from '../../assets/js/src/scripts.js';
//import { default as utils } from '../../../assets/js/src/utils.js';
import { default as utils } from '../../assets/js/src/utils.js';

function init(el, store) {

	const localState = {};
	const isGalaxo = store.getState().floorMap.env === 'galaxo';

	// Listen to history change (back/forward)
	$(window).on('popstate', function(e) {

		store.dispatch({
			type: 'LOAD_ROUTE',
			route: e.originalEvent.state,
			routePopstate: true
		});

	});

	// Update browser history-state on Redux-state update
	store.subscribe(() => {

		var state = store.getState();
		var historyState = {
			floor: state.floorMap.floor,
			step: state.floorMap.step,
			department: state.floorMap.department,
			service: state.floorMap.serviceID,
			business: state.floorMap.business && state.floorMap.business.id,
			premise: state.floorMap.activePremise || state.floorMap.business && state.floorMap.business.premise_primary,
			view: state.floorMap.pane,
			location: state.floorMap.location && state.floorMap.location.name
		};
		var queryString = qs.stringify(utils.deleteBlankProperties(historyState));
		var delimiter = isGalaxo ? '#' : '?';
		var url = `${document.location.origin + document.location.pathname + ((queryString) ? `${delimiter + queryString}` : '')}`;

		if (queryString !== localState.queryString && !state.floorMap.routePopstate) {
			if (window.history) {
				window.history.replaceState(historyState, document.title, url);
			}
		}

		localState.queryString = queryString;

	});

}

export default {
	init
};
