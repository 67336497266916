/* global $ */

/**
 * Magnific popup -plugin setup & configuration
 */

 function init() {
  const galleryOptions = {
    gallery: {
      preload: [0, 2],
      enabled: true,
      arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button
      tCounter: '<span class="mfp-counter">%curr% / %total%</span>', // markup of counter
    },
    type: 'image',
    image: {
      titleSrc: 'title',
      markup: '<div class="mfp-figure">' +
      '<div class="mfp-close"></div>' +
      '<div class="mfp-img"></div>' +
      '<div class="mfp-bottom-bar">' +
      '<div class="mfp-title"></div>' +
      '<div class="mfp-counter"></div>' +
      '</div>' +
      '</div>',
    },
    iframe: {
      titleSrc: 'title',
      markup:
      '<div class="mfp-iframe-scaler">' +
      '<div class="mfp-close"></div>' +
      '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
      '<div class="mfp-bottom-bar">' +
      '<div class="mfp-title"></div>' +
      '<div class="mfp-counter"></div>' +
      '</div>' +
      '</div>'
    },
    callbacks: {
      markupParse: (template, values, item) => {
        const $caption = item.el.closest('figure').find('figcaption');
        values.title = $caption;
      },
    },
    mainClass: 'mfp-fade',
    removalDelay: 300,
  };

  $('.wp-block-image a[href*="kauppakeskusvalkea.fi"], .wp-block-gallery a[href*="kauppakeskusvalkea.fi"]').magnificPopup(galleryOptions);
  $('.wp-block-image:not(.banner) a[href*="kauppakeskusvalkea.fi"], .wp-block-gallery a[href*="kauppakeskusvalkea.fi"]').magnificPopup(galleryOptions);

}

export default {
  init,
};
