var isInitialized = false;
var isOpen = false;
var tooltipEl;
var documentWidth;
var tooltipWidth;
var serviceTexts;

/*

██ ███    ██ ██ ████████ ██  █████  ██      ██ ███████ ███████
██ ████   ██ ██    ██    ██ ██   ██ ██      ██    ███  ██
██ ██ ██  ██ ██    ██    ██ ███████ ██      ██   ███   █████
██ ██  ██ ██ ██    ██    ██ ██   ██ ██      ██  ███    ██
██ ██   ████ ██    ██    ██ ██   ██ ███████ ██ ███████ ███████

*/

function init(el, store) {

	tooltipEl = document.querySelector('.floor-map__tooltip');
	documentWidth = document.body.clientWidth;

	serviceTexts = window.valkeaFloorMapData.serviceTexts;

	window.addEventListener('resize', function() {
		documentWidth = document.body.clientWidth;
	});

	store.subscribe(() => {
		handleChange(el, store);
	});

}

/*

███████ ██    ██ ███████ ███    ██ ████████ ███████
██      ██    ██ ██      ████   ██    ██    ██
█████   ██    ██ █████   ██ ██  ██    ██    ███████
██       ██  ██  ██      ██  ██ ██    ██         ██
███████   ████   ███████ ██   ████    ██    ███████

*/

function registerEvents() {
	document.addEventListener('mousemove', setPosition(null, null), false);
	isInitialized = true;
}

function destroyEvents() {
	close();
	document.removeEventListener('mousemove', setPosition);
	isInitialized = false;
}


function registerUiEvents(el) {

	el.$module.on('subscribeUiEvent.tooltip', function(e, uiState) {

		if (uiState.tooltipOpen) {
			show(uiState.tooltipText);
		} else if (isOpen) {
			close();
		}

	});

}

function destroyUiEvents(el) {
	el.$module.off('subscribeUiEvent.tooltip');
}


/*

██    ██ ██████  ██████   █████  ████████ ███████ ███████
██    ██ ██   ██ ██   ██ ██   ██    ██    ██      ██
██    ██ ██████  ██   ██ ███████    ██    █████   ███████
██    ██ ██      ██   ██ ██   ██    ██    ██           ██
 ██████  ██      ██████  ██   ██    ██    ███████ ███████

*/

function handleChange(el, store) {

	var state = store.getState();

	if (state.root.mql_LG) {

		if (!isInitialized) {
			registerEvents();
			registerUiEvents(el);
		}

	} else if (isInitialized) {
		destroyEvents();
		destroyUiEvents(el);
	}

}

/*

██████   ██████  ███    ███      █████   ██████ ████████ ██  ██████  ███    ██ ███████
██   ██ ██    ██ ████  ████     ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
██   ██ ██    ██ ██ ████ ██     ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
██   ██ ██    ██ ██  ██  ██     ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
██████   ██████  ██      ██     ██   ██  ██████    ██    ██  ██████  ██   ████ ███████

*/

function show(text) {

	var translatedText = serviceTexts[text] ? serviceTexts[text][0] : text;

	if (tooltipEl.textContent !== translatedText) {
		tooltipEl.textContent = translatedText;
	}

	if (!isOpen) {
		tooltipEl.classList.add('is-active');
		tooltipWidth = tooltipEl.offsetWidth;
		isOpen = true;
	}

}


function setPosition(X, Y) {
	return function setPositionWithEvent(event) {
		var pageX = X || (event && event.pageX);
		var pageY = Y || (event && event.pageY);
		var offset = 10;
		var alignLeft = pageX + tooltipWidth + offset > documentWidth;

		if (alignLeft) {
			tooltipEl.setAttribute('data-align', 'right');
			tooltipEl.style.top = `${pageY + offset}px`;
			tooltipEl.style.right = `${documentWidth - pageX + offset}px`;
			tooltipEl.style.left = 'auto';
		} else {
			tooltipEl.setAttribute('data-align', 'left');
			tooltipEl.style.top = `${pageY + offset}px`;
			tooltipEl.style.right = 'auto';
			tooltipEl.style.left = `${pageX + offset}px`;
		}
	};
}

function close() {
	tooltipEl.classList.remove('is-active');
	isOpen = false;
}

/*

███████ ██   ██ ██████   ██████  ██████  ████████ ███████
██       ██ ██  ██   ██ ██    ██ ██   ██    ██    ██
█████     ███   ██████  ██    ██ ██████     ██    ███████
██       ██ ██  ██      ██    ██ ██   ██    ██         ██
███████ ██   ██ ██       ██████  ██   ██    ██    ███████

*/

export default {
	init
};
