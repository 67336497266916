/**
 * Popover
 */

var $module;
var isInitialized = false;
var $activePopover;
var $activePin;
var localState = {};

/*

██ ███    ██ ██ ████████ ██  █████  ██      ██ ███████ ███████
██ ████   ██ ██    ██    ██ ██   ██ ██      ██    ███  ██
██ ██ ██  ██ ██    ██    ██ ███████ ██      ██   ███   █████
██ ██  ██ ██ ██    ██    ██ ██   ██ ██      ██  ███    ██
██ ██   ████ ██    ██    ██ ██   ██ ███████ ██ ███████ ███████

*/

function init(el, store) {

	$module = el.$module;

	store.subscribe(() => {
		handleChange(el, store);
	});

}

/*

███████ ██    ██ ███████ ███    ██ ████████ ███████
██      ██    ██ ██      ████   ██    ██    ██
█████   ██    ██ █████   ██ ██  ██    ██    ███████
██       ██  ██  ██      ██  ██ ██    ██         ██
███████   ████   ███████ ██   ████    ██    ███████

*/

function registerEvents() {

	$(window).on('resize.floormapPopover', function(e) {
		if ($activePopover) {
			setPosition();
		}
	});

	isInitialized = true;

}

function destroyEvents() {
	close();
	$(window).off('resize.floormapPopover');
	isInitialized = false;
}

/*

██    ██ ██████  ██████   █████  ████████ ███████ ███████
██    ██ ██   ██ ██   ██ ██   ██    ██    ██      ██
██    ██ ██████  ██   ██ ███████    ██    █████   ███████
██    ██ ██      ██   ██ ██   ██    ██    ██           ██
 ██████  ██      ██████  ██   ██    ██    ███████ ███████

*/

function handleChange(el, store) {

	const state = store.getState();

	if (state.root.mql_LG) {

		let businessInfo = state.floorMap.business;
		let businessChanged = state.floorMap.selectionType === 'business' && state.floorMap.selectionID !== localState.selectionID;
		let businessReselected = !businessChanged && state.floorMap.popoverClickCoords !== localState.popoverClickCoords;
		let premiseChanged = state.floorMap.activePremise !== localState.activePremise;
		let popoverShouldClose = (businessChanged || businessReselected || premiseChanged || state.stageTransition || !state.floorMap.popoverOpen);

		if (!isInitialized) {
			registerEvents();
		}

		if ($activePopover && popoverShouldClose) {
			close();
		}

		if (!state.floorMap.stageTransition) {
			if (!$activePopover && state.floorMap.popoverOpen && state.floorMap.selectionType === 'business' && !businessInfo.disabled) {
				show(businessInfo.id, state.floorMap.activePremise, state.floorMap.popoverClickCoords);
			}
		}

	} else if (isInitialized) {
		destroyEvents();
	}

	localState = Object.assign({}, state.floorMap);

}

/*

██████   ██████  ███    ███      █████   ██████ ████████ ██  ██████  ███    ██ ███████
██   ██ ██    ██ ████  ████     ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
██   ██ ██    ██ ██ ████ ██     ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
██   ██ ██    ██ ██  ██  ██     ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
██████   ██████  ██      ██     ██   ██  ██████    ██    ██  ██████  ██   ████ ███████

*/

function show(businessID, premiseID, clickCoords, floorOrDepartmentChanged) {

	var $businessShortinfo;
	var $popover;
	var $pin;

	if (!isInitialized) {
		return;
	}

	$businessShortinfo = $(`#business-shortinfo--${premiseID}`).clone();

	$popover = $('<div class="floor-map__popover" />').append($businessShortinfo);

	$module.append($popover);

	$activePopover = $popover;
	$activePin = $pin = $module.find(`.${premiseID}_pin`);

	setPosition($popover, $pin, (floorOrDepartmentChanged ? null : clickCoords));

	$popover.on('transitionend', function(e) {
		if (e.eventPhase === 2 && !$popover.hasClass('is-active')) {
			$popover.remove();
		}
	});

	window.setTimeout(function() {
		$popover.addClass('is-active');
		$module.addClass('has-popover');
	}, 0);

}

function setPosition($popover = $activePopover, $pin = $activePin, clickCoords) {

	var coords = clickCoords || $pin[0].getBoundingClientRect();
	var alignRight = $popover.width() + coords.left + 10 > document.body.clientWidth;
	var pinOffset = $pin[0].getBoundingClientRect().width / 2;

	if (alignRight) {
		$popover.attr('data-align', 'right').css({
			right: document.body.clientWidth - coords.left + (clickCoords ? 5 : -pinOffset),
			left: 'auto',
			top: coords.top + (clickCoords ? 5 : (window.scrollY || window.pageYOffset) + pinOffset)
		});
	} else {
		$popover.attr('data-align', 'left').css({
			right: 'auto',
			left: coords.left + (clickCoords ? 5 : pinOffset),
			top: coords.top + (clickCoords ? 5 : (window.scrollY || window.pageYOffset) + pinOffset)
		});
	}

}

function close() {
	$module.removeClass('has-popover');
	if ($activePopover) {
		$activePopover.removeClass('is-active');
		$activePopover = null;
		$activePin = null;
	}
}

/*

███████ ██   ██ ██████   ██████  ██████  ████████ ███████
██       ██ ██  ██   ██ ██    ██ ██   ██    ██    ██
█████     ███   ██████  ██    ██ ██████     ██    ███████
██       ██ ██  ██      ██    ██ ██   ██    ██         ██
███████ ██   ██ ██       ██████  ██   ██    ██    ███████

*/

export default {
	init
};
