//import { default as utils } from '../../../assets/js/src/utils.js';
import { default as utils } from '../../assets/js/src/utils.js';

var $panesContainer;
var $panes;
var localState = {};

function init(el, store) {

	$panesContainer = $('.floor-map__panes');
	$panes = $('.floor-map__pane');

	$panesContainer.on(utils.transitionEndEventName, function(e) {
		if (e.eventPhase === 2 && e.originalEvent.propertyName === 'height') {
			$panesContainer.removeClass('is-animated');
			window.setTimeout(function() {
				$panesContainer.removeAttr('style');

				store.dispatch({
					type: 'PANE_TRANSITION_END'
				});

			}, 1);
		}
	});

	store.subscribe(() => {
		handleChange(el, store);
	});

}

function handleChange(el, store) {
	var state = store.getState();
	if (state.floorMap.pane !== localState.pane) {
		selectView(state.floorMap.pane);
	}
	localState.pane = state.floorMap.pane;
}

function selectView(type) {

	var $activePane = $panes.filter(`[data-type="${type || 'floor'}"]`);

	$panesContainer.css({
		height: $panesContainer.height()
	});

	window.setTimeout(function() {
		$panes.removeClass('is-active');
		$activePane.addClass('is-active');

		if ($panesContainer.height() !== $activePane.outerHeight()) {
			$panesContainer.addClass('is-animated');
			$panesContainer.height($activePane.height());
		} else {
			$panesContainer.removeAttr('style');
		}

	}, 1);

}

export default {
	init
};
