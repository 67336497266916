// Polyfills
import 'focus-visible';
import 'element-qsa-scope';
import smoothScrollPolyfill from 'smoothscroll-polyfill';
import { combineReducers } from 'redux';
import utils from '../js/src/utils.js';

// Plugins & frameworks
// import 'alpinejs';

import navPanel from '../../components/nav-panel/nav-panel';
import navMobile from '../../components/nav-mobile/nav-mobile';
import topBar from '../../components/top-bar/top-bar';
import lazyLoad from '../../components/lazy-load/lazy-load';
import gravityForms from '../../components/gravity-forms/gravity-forms';
import carousels from '../../blocks/carousel/carousel';
import accordion from '../../blocks/accordion/accordion';
import starRating from '../../blocks/star-rating/star-rating';
import langSwitcher from '../../components/lang-switcher/lang-switcher';
// import tablist from '../../components/tablist/tablist';
// import scrollAnimations from '../../components/scroll-animations/scroll-animations';
import googleMap from '../../blocks/google-map/google-map';
import adCarousel from '../../blocks/ad-carousel/ad-carousel';
// import postListFilters from '../../blocks/post-list-filters/post-list-filters';
// import { debounce } from 'lodash-es';
import magnificPopup from '../../components/magnific-popup/magnific-popup';

// Components - shared
import { default as floorMapWeb } from '../../components-shared/floor-map/floor-map-web.js';
//import { default as floorMapGalaxo } from '../../components-shared/floor-map/floor-map-galaxo.js';
//import { default as closingTimer } from '../../components/closing-timer/closing-timer.js';

const appStart = () => {

  // Make WP-core jQuery available globally
  window.$ = window.jQuery;

  smoothScrollPolyfill.polyfill();

  navPanel.init();
  navMobile.init();
  topBar.init();
  lazyLoad.init();
  gravityForms.init();
  carousels.init();
  accordion.init();
  starRating.init();
  langSwitcher.init();
  // tablist.init();
  // scrollAnimations.init();
  googleMap.init();
  adCarousel.init();
  // postListFilters.init();
  magnificPopup.init();
};

// 'DOMContentLoaded' may fire before your script has a chance to run.
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', appStart);
} else {
  appStart();
}


document.addEventListener('DOMContentLoaded', function(event) {

  var mql = window.matchMedia('(min-width: 768px)');
	/*
	 * Initialize root actions, reducers and the Redux store
	 */

	var mql_XS = window.matchMedia('(max-width: 767px)');
	var mql_LG = window.matchMedia('(min-width: 992px)');

	var initialState = {
		root: {
			mql_XS: mql_XS.matches,
			mql_LG: mql_LG.matches
		}
	};

	var reducers = combineReducers({
		root: rootReducer,
		floorMap: floorMapWeb.reducer
	});

	var store = utils.createDevtoolsStore(reducers, initialState);
	// store.dispatch({
	// 	type: 'INIT_FO_REALZ'
	// });

	mql_XS.addEventListener(mql, () => {
		store.dispatch({
			type: 'MEDIA_BREAKPOINT',
			mql_XS: mql_XS.matches,
			mql_LG: mql_LG.matches
		});
	});

	mql_LG.addEventListener(mql, () => {
		store.dispatch({
			type: 'MEDIA_BREAKPOINT',
			mql_XS: mql_XS.matches,
			mql_LG: mql_LG.matches
		});
	});

	/*
	 * Initialize components
	 */

	floorMapWeb.init(store);

});


function rootReducer(oldState, action) {

	var state = Object.assign({}, oldState);

	switch (action.type) {
	case 'MEDIA_BREAKPOINT':
		state.mql_XS = action.mql_XS;
		state.mql_LG = action.mql_LG;
		break;
	}

	return state;
}
