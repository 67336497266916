import { createStore } from 'redux';
// import { es6promisePolyfill } from './scripts.js'; // eslint-disable-line
// import { fetchPolyfill } from './scripts.js'; // eslint-disable-line


/**
 * Debounce
 *
 * Returns a function, which, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * Usage:
 * var myEfficientFn = debounce(function() {
 *   // All the taxing stuff you do
 * }, 250);
 * window.addEventListener('resize', myEfficientFn);
 *
 */
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this;
		var args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) {
				func.apply(context, args);
			}
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) {
			func.apply(context, args);
		}
	};
}


/**
 * Poll for condition
 *
 * Usage: ensure element is visible
 * poll(
 *     function() {
 *         return document.getElementById('lightbox').offsetWidth > 0;
 *     },
 *     function() {
 *         // Done, success callback
 *     },
 *     function() {
 *         // Error, failure callback
 *     }
 * );
 */
function poll(fn, callback, errback, timeout, _interval) {
	var endTime = Number(new Date()) + (timeout || 2000);
	var interval = _interval || 100;

	(function p() {
		// If the condition is met, we're done!
		if (fn()) {
			callback();
		} else if (Number(new Date()) < endTime) {
			// If the condition isn't met but the timeout hasn't elapsed, go again
			setTimeout(p, interval);
		} else {
			// Didn't match and too much time, reject!
			errback(new Error('timed out for ' + fn + ': ' + arguments));
		}
	}());
}

/**
 * Get absoluteUrl
 *
 * Usage:
 * getAbsoluteUrl('/something'); // http://davidwalsh.name/something
 *
 * @param (string) url
 */
var getAbsoluteUrl = (function() {
	var a;

	return function(url) {
		if (!a) {
			a = document.createElement('a');
		}
		a.href = url;

		return a.href;
	};
}());

/**
 * Run function only once
 *
 * Usage:
 * var canOnlyFireOnce = once(function() {
 *   console.log('Fired!');
 * });
 * canOnlyFireOnce(); // "Fired!"
 * canOnlyFireOnce(); // nada
 *
 */
function once(fn, context) {
	var result;

	return function() {
		if (fn) {
			result = fn.apply(context || this, arguments);
			fn = null; // eslint-disable-line no-param-reassign
		}
		return result;
	};
}

/**
 * Get transitionend -event name, with prefix if necessary
 *
 * http://stackoverflow.com/questions/5023514/how-do-i-normalize-css3-transition-functions-across-browsers
 */
function getTransitionEndEventName() {

	var i;
	var el = document.createElement('div');
	var transitions = {
		'transition': 'transitionend',
		'OTransition': 'otransitionend', // oTransitionEnd in very old Opera
		'MozTransition': 'transitionend',
		'WebkitTransition': 'webkitTransitionEnd'
	};

	for (i in transitions) {
		if (transitions.hasOwnProperty(i) && el.style[i] !== undefined) {
			return transitions[i];
		}
	}


}

const transitionEndEventName = getTransitionEndEventName();



/**
 * Replace all occurences of a string
 * http://stackoverflow.com/questions/1144783/replacing-all-occurrences-of-a-string-in-javascript
 */
function replaceAll(str, find, replace) {
	return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

function escapeRegExp(str) {
	return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}





export var setBodyOverflow = (function() { // eslint-disable-line vars-on-top

	var scrolled = 0;

	return {
		bodyStyleBackup: {},
		hide: function() {
			scrolled = window.pageYOffset;
			this.bodyStyleBackup = {
				paddingRight: document.body.style.paddingRight,
				overflowY: document.body.style.overflowY
			};
			document.body.style.paddingRight = `${getScrollbarWidth()}px`;
			document.body.style.overflowY = 'hidden';
		},
		show: function() {
			Object.keys(this.bodyStyleBackup).forEach((key) => {
				document.body.style[key] = this.bodyStyleBackup[key];
			});
			window.scroll(0, scrolled);
		}
	};

}());

function getScrollbarWidth() {
	return window.innerWidth - document.documentElement.clientWidth;
}



/**
 * Get datetime from server
 * http://stackoverflow.com/questions/220231/accessing-the-web-pages-http-headers-in-javascript#answer-220233
 */
function getServerDate() {

	var delimiter = (document.location.href.indexOf('?') > -1) ? '&' : '?';

	return window.fetch(`${document.location.href}${delimiter}_=${new Date().getTime()}`, {
		method: 'HEAD'
	}).then(function(response) {
		return new Date(response.headers.get('Date'));
	}).catch(function(err) {
		// Error :(
	});

}

// https://github.com/camwest/left-pad
function leftpad (str, len, ch) {
	str = String(str);

	var i = -1;

	if (!ch && ch !== 0) ch = ' ';

	len = len - str.length;

	while (++i < len) {
		str = ch + str;
	}

	return str;
}

/**
 * Delete all null (or undefined) properties from an object.
  * http://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
 * Set 'recurse' to true if you also want to delete properties in nested objects.
 */
function deleteBlankProperties(obj, recurse) {

	var cleaned = Object.assign({}, obj);

	for (let key of Object.keys(cleaned)) {
		if (cleaned[key] === null || typeof cleaned[key] === 'undefined') {
			delete cleaned[key];
		} else if (recurse && typeof cleaned[key] === 'object') {
			deleteBlankProperties(cleaned[key], recurse);
		}
	}

	return cleaned;
}

/*
 * Redux observeStore helper
 * https://github.com/rackt/redux/issues/303#issuecomment-125184409
 * http://stackoverflow.com/questions/34808196/compare-previous-state
 *
 * Example:
 * observeStore(store, x => x, function(curr, next){
 *     console.log('Current: ' + JSON.stringify(curr) + ', Next: ' + JSON.stringify(next));
 * });
 */
// function observeStore(store, select, onChange) {
// 	let currentState;
//
// 	function handleChange() {
// 		let nextState = select(store.getState());
// 		if (nextState !== currentState) {
// 			onChange(currentState, nextState);
// 			currentState = nextState;
// 		}
// 	}
//
// 	let unsubscribe = store.subscribe(handleChange);
// 	handleChange();
// 	return unsubscribe;
// }


// Redux-store wrapper for Chrome/Firefox Redux Devtools extension
function createDevtoolsStore(reducer, initialState) {
	const store = createStore(reducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
	return store;
}


/**
 * Exports
 */
export default {
	debounce,
	poll,
	getAbsoluteUrl,
	once,
	transitionEndEventName,
	replaceAll,
	getServerDate,
	leftpad,
	deleteBlankProperties,
	createDevtoolsStore
	// observeStore
};
