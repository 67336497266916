function init(el) {

	/**
	 * Show today's opening hours in BusinessShortinfos
	 */

	window.$ = window.jQuery;

	var day = new Date().getDay();
	var $day;

	if (day === 0) {
		$day = el.$businessShortinfos.find('.business-shortinfo__day.day--sunday');
	} else if (day === 6) {
		$day = el.$businessShortinfos.find('.business-shortinfo__day.day--saturday');
	} else {
		$day = el.$businessShortinfos.find('.business-shortinfo__day.day--weekday');
	}

	$day.addClass('is-active');

}

export default {
	init,
};
