/**
 * Trail
 */

function init(el, store) {

	var trailFloorEl = document.querySelector('.floor-map__trail-floor');
	var trailTitleEl = document.querySelector('.floor-map__trail-title');
	var oldState = { floorMap: {} };
	var isGalaxo = $(document.body).is('.galaxo');
	var titleTexts = window.valkeaFloorMapData.titleTexts;
	var serviceTexts = window.valkeaFloorMapData.serviceTexts;

	// Back button
	el.$module.on('click', '.floor-map__trail-back', function(e) {
		store.dispatch({
			type: 'STEP_BACK'
		});
	});

	// "Show premise ids" button
	el.$module.on('click', '.floor-map__show-premises', function(e) {
		el.$module.toggleClass('show-premise-ids')
	});

	store.subscribe(() => {

		var state = store.getState();
		var trailFloor;
		var trailTitle;
		var stepChanged = state.floorMap.step !== oldState.floorMap.step;
		var selectionChanged = state.floorMap.selectionID !== oldState.floorMap.selectionID;
		var departmentChanged = state.floorMap.department !== oldState.floorMap.department;
		var floorChanged = state.floorMap.floor !== oldState.floorMap.floor;

		if (stepChanged || selectionChanged || departmentChanged || floorChanged) {

			if (!state.floorMap.floor) {

				trailFloor = '';
				trailTitle = titleTexts['Valitse kerros'];

			} else if (isGalaxo) {

				if (state.floorMap.myLocation) {
					trailFloor = `${state.floorMap.floor}. ${titleTexts['kerros']}`;
					trailTitle = titleTexts['Sijaintisi'];
				} else if (state.floorMap.serviceID) {
					trailFloor = `${state.floorMap.floor}. ${titleTexts['kerros']}`;
					trailTitle = serviceTexts[state.floorMap.serviceData ? state.floorMap.serviceData.title : state.floorMap.serviceID][1];
				} else if (!state.floorMap.department) {
					trailFloor = '';
					//trailTitle = `${state.floorMap.floor}. ${titleTexts['kerros']}`;

					trailFloor = `${state.floorMap.floor}. ${titleTexts['kerros']}`;
					trailTitle = "Valitse palvelu";
				} else {
					trailFloor = `${state.floorMap.floor}. ${titleTexts['kerros']}`;
					//trailTitle = state.floorMap.department;
					trailTitle = "Valittu palvelu";
				}

			} else {

				//trailFloor = `${state.floorMap.floor}. ${titleTexts.kerros}`;
				trailFloor = state.floorMap.floor + ". kerros";
				//trailFloor = `${state.floorMap.floor}. ${titleTexts['kerros']}`;
				console.log("state:" + state.floorMap.floor);

				if (state.floorMap.serviceID) {
					//trailFloor = "";
					trailTitle = serviceTexts[state.floorMap.serviceData ? state.floorMap.serviceData.title : state.floorMap.serviceID][1];
				} else if (!state.floorMap.department) {
					//trailTitle = titleTexts['Valitse alue'];
					trailTitle = "Valitse palvelu";
				} else {
					//trailTitle = state.floorMap.department;
					trailTitle = "Valittu palvelu";
				}

			}

			trailFloorEl.textContent = trailFloor;
			trailTitleEl.textContent = trailTitle;

		}

		oldState = Object.assign({}, state);

	});

}

export default {
	init
};
