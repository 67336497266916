var $businessShortinfosWrap;
var $businessShortinfos;
var $activeBusinessShortinfo;
var isActive = false;
var localState = {};
var $ = window.jQuery;

function init(el, store) {

	$businessShortinfosWrap = $('.business-shortinfo-container');
	$businessShortinfos = el.$businessShortinfos;

	store.subscribe(() => {
		handleChange(el, store);
	});


	if (store.getState().floorMap.env === 'galaxo') {
		$businessShortinfosWrap.on('mousedown', 'a', function() {
			setTimeout(() => { document.location.href = this.href; }, 150);
		});
	}

}

function handleChange(el, store) {

	var state = store.getState();
	var selectionChanged = state.floorMap.selectionID !== localState.selectionID;
	var premiseChanged = state.floorMap.activePremise !== localState.activePremise;

	if (state.root.mql_LG && state.floorMap.env !== 'galaxo') {

		if (isActive) {
			destroyEvents();
		}

	} else {

		if (!isActive) {
			registerEvents();
		}

		if (state.floorMap.footerInfoOpen) {
			if (!$activeBusinessShortinfo || selectionChanged || (premiseChanged && state.floorMap.business.has_multiple_titles)) {
				show(state.floorMap.activePremise);
			}
		} else if (localState.footerInfoOpen) {
			close();
		}

	}

	localState = Object.assign({}, state.floorMap);

}

function registerEvents() {

	// $footerWrap.on('transitionend', function(e) {
	// 	if (e.eventPhase === 2 && e.originalEvent.propertyName === 'height') {
	// 		$footerWrap.removeClass('is-animated');
	// 		window.setTimeout(function() {
	// 			$footerWrap.removeAttr('style');
	// 		}, 1);
	// 	}
	// });

	isActive = true;
}

function destroyEvents() {
	close();
	// $footerWrap.off('transitionend');
	isActive = false;
}

function show(premiseID) {

  window.$ = window.jQuery;

	$activeBusinessShortinfo = $(`#business-shortinfo--${premiseID}`);
	$businessShortinfosWrap.height($businessShortinfosWrap.height());

	//$businessShortinfos.classList.remove('is-active');
	//$activeBusinessShortinfo.classList.add('is-active');
	$businessShortinfos.removeClass('is-active');
	$activeBusinessShortinfo.addClass('is-active');

	$businessShortinfosWrap.stop(true).animate({ 'height': $activeBusinessShortinfo.outerHeight() }, 200, function() {
		//$businessShortinfosWrap.removeAttribute('style');
		$businessShortinfosWrap.removeAttr('style');
	});

}

function close() {

	$businessShortinfosWrap.stop(true).animate({ 'height': 0 }, 200, function() {
		$businessShortinfos.removeClass('is-active');
		$businessShortinfosWrap.removeAttr('style');
		$activeBusinessShortinfo = null;
	});

}

export default {
	init: init
};
